/* eslint-disable react/prop-types */
import { useState, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'
import { FaPlus } from 'react-icons/fa6'
import 'swiper/css'
import 'swiper/css/pagination'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import { classNames } from '@utils/formatFunctions'
import { useUpdateProfilePhotos, useGetProfilePhotos } from '@services/user'

function ProfilePhotos() {
  const { data, isPending, isError } = useGetProfilePhotos()
  const { mutate, isPending: mutationIsPending } = useUpdateProfilePhotos()
  const [isOpen, setIsOpen] = useState(false)
  const [uploadedPhotos, setUploadedPhotos] = useState([])
  const [deletedPhotos, setDeletedPhotos] = useState([])
  const fileInputRef = useRef(null)

  const handleFileInput = (e) => {
    if (e.target.files) {
      const files = Array.from(e.target.files).filter((file) =>
        file.type.startsWith('image/'),
      )
      setUploadedPhotos((prev) => [...prev, ...files])
    }
  }

  const removePhoto = (index) => {
    setUploadedPhotos((prev) => prev.filter((_, i) => i !== index))
  }

  const deletePhoto = (photoId) => {
    setDeletedPhotos((prev) => [...prev, photoId])
  }

  const handleUpdate = () => {
    const formData = new FormData()
    uploadedPhotos.forEach((photo) => {
      formData.append('uploadedPhotos', photo)
    })
    formData.append('deletedPhotos', JSON.stringify(deletedPhotos))
    mutate(formData, {
      onSuccess: () => {
        setIsOpen(false)
        setUploadedPhotos([])
        setDeletedPhotos([])
      },
    })
  }

  return (
    <>
      <div>
        <div className="flex flex-row items-center gap-2 font-tex text-xl">
          <h1>Fotos de perfil</h1>
          <p>📸</p>
        </div>
        <Swiper
          spaceBetween={2}
          slidesPerView={'auto'}
          freeMode={true}
          scrollbar={{ draggable: true }}
          modules={[Pagination]}
          className="min-h-72"
        >
          {isPending &&
            isError &&
            Array.from({ length: 4 }).map((_, index) => (
              <SwiperSlide
                key={index}
                className="m-4 size-72 animate-pulse rounded-lg bg-grey-sec transition-transform hover:scale-105"
              ></SwiperSlide>
            ))}
          {data?.map((photo) => (
            <SwiperSlide key={photo.id} className="m-4 size-72 cursor-pointer">
              <img
                src={photo.url}
                onClick={() => setIsOpen(true)}
                alt="profilePhoto"
                className="rounded-lg object-cover transition-transform hover:scale-105"
              />
            </SwiperSlide>
          ))}
          {data?.length === 0 && (
            <SwiperSlide
              onClick={() => setIsOpen(true)}
              className="m-4 flex size-72 cursor-pointer items-center justify-center rounded-lg bg-grey-soft text-grey-base transition-transform hover:scale-105"
            >
              <FaPlus size={64} />
            </SwiperSlide>
          )}
        </Swiper>
      </div>
      <CustomModal
        header={
          <ModalHeader
            title="Actualiza tus fotos"
            subtitle="Agrega o elimina fotos de tu perfil para que los dueños de mascotas puedan conocerte mejor"
          />
        }
        body={
          <ModalBody
            fileInputRef={fileInputRef}
            handleFileInput={handleFileInput}
            uploadedPhotos={uploadedPhotos}
            removePhoto={removePhoto}
            profilePhotos={data}
            deletePhoto={deletePhoto}
            deletedPhotos={deletedPhotos}
            handleUpdate={handleUpdate}
            isPending={mutationIsPending}
          />
        }
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}

const ModalBody = ({
  fileInputRef,
  handleFileInput,
  uploadedPhotos,
  removePhoto,
  profilePhotos,
  deletePhoto,
  deletedPhotos,
  handleUpdate,
  isPending,
}) => {
  const updateEnabled = uploadedPhotos.length > 0 || deletedPhotos.length > 0
  return (
    <div className="space-y-4">
      <input
        ref={fileInputRef}
        type="file"
        multiple
        accept="image/*"
        onChange={handleFileInput}
        className="hidden"
        id="file-input"
      />
      <div className="mt-4 grid grid-cols-3 gap-4">
        {uploadedPhotos.length > 0 &&
          uploadedPhotos.map((photo, index) => (
            <div
              key={index}
              className="group relative cursor-pointer transition-transform hover:scale-105"
              onClick={() => removePhoto(index)}
            >
              <img
                src={URL.createObjectURL(photo)}
                alt="profilePhoto"
                className="size-24 w-full rounded-lg object-cover"
              />
              <button
                className="absolute right-1 top-1 flex size-6 items-center justify-center rounded-full bg-red-base text-white opacity-0 transition duration-200 group-hover:opacity-100"
                aria-label="Eliminar foto"
              >
                &times;
              </button>
            </div>
          ))}
        {profilePhotos &&
          profilePhotos
            .filter((photo) => !deletedPhotos.includes(photo.id))
            .map((photo) => (
              <div
                key={photo.id}
                className="group relative cursor-pointer transition-transform hover:scale-105"
                onClick={() => deletePhoto(photo.id)}
              >
                <img
                  src={photo.url}
                  alt="profilePhoto"
                  className="size-24 w-full rounded-lg object-cover"
                />
                <button
                  className="absolute right-1 top-1 flex size-6 items-center justify-center rounded-full bg-red-base text-white opacity-0 transition duration-200 group-hover:opacity-100"
                  aria-label="Eliminar foto"
                >
                  &times;
                </button>
              </div>
            ))}
      </div>
      <button
        className="inline-block w-full rounded-lg border border-blue-soft px-8 py-3.5 text-sm font-medium text-blue-soft transition hover:scale-105 hover:shadow-xl focus:outline-none focus:ring active:bg-blue-100"
        type="button"
        onClick={() => fileInputRef.current.click()}
      >
        Seleccionar fotos
      </button>
      <button
        className={classNames(
          'inline-block w-full rounded-lg bg-blue-soft px-8 py-3.5 text-sm font-medium transition focus:outline-none focus:ring',
          !updateEnabled && 'cursor-not-allowed bg-grey-soft text-grey-base',
          updateEnabled > 0 &&
            'text-white hover:scale-105 hover:bg-blue-600 hover:shadow-xl active:bg-blue-600',
        )}
        type="button"
        onClick={handleUpdate}
        disabled={!updateEnabled}
      >
        {isPending ? (
          <div className="flex items-center justify-center">
            <div className="h-4 w-4 animate-spin rounded-full border-2 border-t-2 border-blue-soft"></div>
          </div>
        ) : (
          'Actualizar fotos'
        )}
      </button>
    </div>
  )
}

export default ProfilePhotos
