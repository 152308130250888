/* eslint-disable react/prop-types */
import { FaPlus } from 'react-icons/fa6'
import { useGetProfile } from '@services/user'
import { useState } from 'react'
import { useUpdateUser } from '@services/user'
import { ABOUT_ME } from '@assets/constants'
import { useCallback } from 'react'
import { useMemo } from 'react'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import LoadingButton from '@common/buttons/LoadingButton'

function AboutMe() {
  const { data: user } = useGetProfile()
  const [isOpen, setIsOpen] = useState(false)
  const aboutMe = useMemo(
    () =>
      ABOUT_ME.filter((item) =>
        user?.aboutMe?.some((aboutItem) => aboutItem === item.value),
      ),
    [user],
  )

  return (
    <>
      <div className="space-y-4">
        <h2 className="font-tex text-xl">Acerca de mi 🐶</h2>
        <div className="flex flex-wrap gap-2">
          {aboutMe?.length > 0 ? (
            aboutMe.map((item, index) => (
              <div
                key={index}
                onClick={() => setIsOpen(true)}
                className="cursor-pointer rounded-full bg-blue-ter px-2 py-1.5 text-xs text-white transition-all duration-200 hover:scale-95"
              >
                <p>{item.label}</p>
              </div>
            ))
          ) : (
            <button
              onClick={() => setIsOpen(true)}
              className="flex flex-row items-center gap-2 rounded-xl bg-blue-ter px-4 py-2"
            >
              <FaPlus size={10} className="text-white" />
              <p className="text-sm text-white">
                Agrega información sobre ti
              </p>
            </button>
          )}
        </div>
      </div>
      <CustomModal
        header={
          <ModalHeader
            title="Acerca de mi"
            subtitle="Agrega tags sobre ti para que los usuarios puedan conocerte mejor"
          />
        }
        body={<ModalBody setIsOpen={setIsOpen} />}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}

const Item = ({ item, selectedItems, toggleItemSelection }) => {
  const isSelected = selectedItems.includes(item.value)
  const handlePress = useCallback(
    () => toggleItemSelection(item.value),
    [toggleItemSelection, item.value],
  )
  return (
    <div
      key={item.id}
      onClick={handlePress}
      className={`cursor-pointer rounded-full px-3 py-1.5 text-xs text-white transition-all duration-200 hover:scale-95 ${
        isSelected ? 'bg-blue-ter' : 'bg-grey-sec'
      }`}
    >
      <p>{item.label}</p>
    </div>
  )
}

function ModalBody({ setIsOpen }) {
  const { data: user } = useGetProfile()
  const { mutate: updateUser, isPending: updateIsPending } = useUpdateUser()
  const [selectedItems, setSelectedItems] = useState(user?.aboutMe || [])

  const toggleItemSelection = useCallback((value) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(value)
        ? prevSelectedItems.filter((item) => item !== value)
        : [...prevSelectedItems, value],
    )
  }, [])

  const handleUpdate = useCallback(() => {
    updateUser({ aboutMe: selectedItems })
    setIsOpen(false)
  }, [updateUser, selectedItems, setIsOpen])

  return (
    <div className="flex flex-col gap-2">
      <div className="flex max-w-xl flex-wrap gap-2">
        {ABOUT_ME.map((item) => (
          <Item
            key={item.id}
            item={item}
            selectedItems={selectedItems}
            toggleItemSelection={toggleItemSelection}
          />
        ))}
      </div>
      <LoadingButton
        loading={updateIsPending}
        textColor={'white'}
        bgColor={'blue'}
        circleColor={'white'}
        fillColor={'blue'}
        text={'Guardar'}
        onClick={handleUpdate}
      />
    </div>
  )
}

export default AboutMe
