import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { postData, getData, putData } from '@services/requests'
import { toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

const notifySuccess = (message) => toast.success(`${message} 🎉`)
const notifyError = (message) => toast.error(`Error: ${message} 😢`)

export const useCreateServiceRecords = () => {
  return useMutation({
    mutationFn: async (data) => {
      const body = {
        ...data,
      }
      return await postData('service-record', body)
    },
    onSuccess: () => {
      notifySuccess('Servicio creado correctamente 🎉')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useUpdateRecords = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ id, body }) => {
      const data = await putData(`service-record/admin/${id}`, body)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['adminRecords'] })
      notifySuccess('Servicio actualizado correctamente 🎉')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useGetRecords = () => {
  return useQuery({
    queryKey: ['adminRecords'],
    queryFn: () => getData('service-record/admin-service-records'),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
  })
}
