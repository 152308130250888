import { useGetProfile } from '@services/user'
import { useNavigate } from 'react-router-dom'
import { Menu } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import profilePhoto from '@assets/images/others/profile.png'
import StarsRating from '@common/utils/StarsRating'

import ShareProfile from '@common/share/ShareProfile'

function UserSummary() {
  const { data: user } = useGetProfile()
  const navigate = useNavigate()

  return (
    <div className="relative mt-[20%] flex flex-col items-center justify-center rounded-lg bg-white drop-shadow-xl">
      <img
        className={`absolute z-10 w-[40%] ${user?.isSitter ? 'translate-y-[-80%]' : 'translate-y-[-50%]'} rounded-full shadow-lg`}
        src={user?.photoUrl || profilePhoto}
        alt="Profile photo"
      />
      <div className="absolute right-4 top-4">
        <Menu as="div" className="relative">
          <Menu.Button className="flex items-center rounded-lg bg-white text-grey-base hover:bg-grey-soft">
            <EllipsisVerticalIcon className="h-7 w-7" aria-hidden="true" />
          </Menu.Button>
          <Menu.Items className="absolute right-0 z-10 mt-1 w-40 origin-top-right rounded-md bg-white text-xs shadow-lg ring-1 ring-black ring-opacity-5 hover:bg-grey-soft focus:outline-none">
            <Menu.Item>
              <button
                onClick={() => navigate('/user/settings')}
                className="block px-4 py-2 text-xs"
              >
                Configuración
              </button>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>

      <div className="flex w-full flex-col items-center gap-4 px-3 pb-3 pt-[25%] text-center">
        <div className="space-y-1">
          <p className="font-mont-semi text-xl text-slate-900">
            {user?.name} {user?.lastName}
          </p>

          {user?.isSitter && (
            <div className="mx-auto w-fit">
              <StarsRating
                count={5}
                value={user?.stars}
                size={16}
                color={'#D9D9D9'}
                activeColor="#ffd700"
                isHalf={true}
              />
            </div>
          )}
        </div>
        <div className="flex w-full flex-col justify-center gap-6">
          <div className="space-y-1">
            <p className="text-sm text-grey-sec">{user?.email}</p>
            <div className="flex justify-center gap-2">
              {user?.isAdmin && (
                <p className="rounded-full bg-blue-base px-6 py-0.5 text-xs text-white">
                  Admin
                </p>
              )}
              {user?.isSitter && (
                <p className="rounded-full bg-yellow-base px-6 py-0.5 text-xs text-white">
                  Sitter
                </p>
              )}
            </div>
          </div>

          {user?.isSitter && <ShareProfile />}
        </div>
      </div>
    </div>
  )
}

export default UserSummary
