import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { postData, putData } from '@services/requests'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AuthContext } from '@common/context/AuthContext'
//import useFilter from '@zustand/filter'
import 'react-toastify/dist/ReactToastify.css'

const notifySuccess = (message) => toast.success(`${message} 🎉`)
const notifyError = (message) => toast.error(`Error: ${message} 😢`)

export const useSignIn = () => {
  const { setAuthToken } = useContext(AuthContext)
  const location = useLocation()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (values) => await postData('auth/sign-in', values),
    onSuccess: (response) => {
      const { accessToken } = response
      setAuthToken(accessToken)
      navigate(location.state?.from || '/')
    },
    onError: (error) => notifyError(error.message),
  })
}

export const useSignUp = () => {
  const { setAuthToken } = useContext(AuthContext)
  const location = useLocation()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (values) => await postData('auth/sign-up', values),
    onSuccess: (data) => {
      const { accessToken } = data
      setAuthToken(accessToken)
      const redirectPath = location.state?.from || '/'
      navigate(redirectPath)
    },
    onError: (error) => notifyError(error.message),
  })
}

export const useGoogleAuth = (setIsOpen, setGoogleToken) => {
  const { setAuthToken } = useContext(AuthContext)
  const location = useLocation()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (credentialResponse) => {
      const { credential } = credentialResponse
      return await postData('auth/google-web', { token: credential })
    },
    onSuccess: (data) => {
      const { accessToken } = data
      setAuthToken(accessToken)
      const redirectPath = location.state?.from || '/'
      navigate(redirectPath)
    },
    onError: (error, variables) => {
      if (error.status === 404) {
        setIsOpen(true)
        setGoogleToken(variables)
      }
      notifyError(error.message)
    },
  })
}

export const useCompleteRegistration = () => {
  const { setAuthToken } = useContext(AuthContext)
  const location = useLocation()
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (values) =>
      await postData('auth/complete-registration', values),
    onSuccess: (data) => {
      const { accessToken } = data
      setAuthToken(accessToken)
      const redirectPath = location.state?.from || '/'
      navigate(redirectPath)
    },
    onError: (error) => notifyError(error.message),
  })
}

export const useForgetPassword = () => {
  return useMutation({
    mutationFn: async (values) => {
      const data = await postData('auth/forget-password', values)
      return data
    },
    onSuccess: () => {
      notifySuccess('Correo enviado correctamente')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useUpdatePassword = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ value, token }) => {
      const body = {
        password: value.password,
        confirmPassword: value.confirm_password,
      }
      const headers = token ? { Authorization: `Bearer ${token}` } : {}
      const data = await putData('auth/reset-password', body, headers)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] })
      notifySuccess('Contraseña actualizada correctamente')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}
