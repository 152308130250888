/* eslint-disable react/prop-types */
import { useGetProfile } from '@services/user'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  useCreateOcInscription,
  useCommitOcInscription,
} from '@services/payment'
import { useUpdateUser } from '@services/user'
import CreditCard from './CreditCard'

function BankData() {
  const { data: user } = useGetProfile()
  const [searchParams] = useSearchParams()
  const [tokenOc, setTokenOc] = useState(null)
  const [urlOc, setUrlOc] = useState(null)
  const { mutate: createOcInscription } = useCreateOcInscription()
  const { mutate: commitOcInscription } = useCommitOcInscription()
  const { mutate: updateUser } = useUpdateUser()

  useEffect(() => {
    createOcInscription(
      {
        returnUrl: window.location.href,
      },
      {
        onSuccess: (data) => {
          setTokenOc(data.token)
          setUrlOc(data.url_webpay)
        },
      },
    )
  }, [createOcInscription])

  useEffect(() => {
    const TBK_TOKEN = searchParams.get('TBK_TOKEN')
    if (TBK_TOKEN) {
      commitOcInscription({ token: TBK_TOKEN })
      searchParams.delete('TBK_TOKEN')
    }
    window.history.replaceState({}, document.title, window.location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  return (
    <div>
      <h3 className="font-tex-bold text-base font-semibold leading-7 text-gray-900">
        Tarjetas Bancarias
      </h3>
      <p className="mt-1 text-sm leading-6 text-gray-500">
        💳 Maneja tus tarjetas con facilidad
      </p>

      {user.tbkOcInfo ? (
        <ul
          role="list"
          className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6"
        >
          <li className="flex flex-col items-start justify-start gap-4 py-4">
            <CreditCard
              cardNumber={user.tbkOcInfo?.cardNumber}
              type={user.tbkOcInfo?.cardType}
            />

            <button
              type="button"
              onClick={() => updateUser({ tbkOcInfo: null })}
              className="ml-4 mt-4 text-sm font-semibold leading-6 text-red-base hover:text-red-600"
            >
              Eliminar tarjeta
            </button>
          </li>
        </ul>
      ) : (
        <div className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <button
            type="button"
            className="mt-4 text-sm font-semibold leading-6 text-blue-soft hover:text-blue-600"
          >
            {urlOc && tokenOc && (
              <form method="post" action={urlOc} className="w-full">
                <input type="hidden" name="TBK_TOKEN" value={tokenOc} />
                <input
                  type="submit"
                  value="+ Agrega una tarjeta bancaria"
                  className="w-full cursor-pointer rounded-md"
                />
              </form>
            )}
          </button>
        </div>
      )}
    </div>
  )
}

export default BankData
