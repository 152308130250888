import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { useGetProfile } from '@services/user'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getData, putData, postData, deleteData } from '@services/requests'
import { saveFilter } from '@store/reducers/filterSlice'
import { setSitters } from '@store/reducers/sitterSlice'
import useFilter from '@zustand/filter'

const notifySuccess = (message) => toast.success(`${message} 🎉`)
const notifyError = (message) => toast.error(`Error: ${message} 😢`)

export const usePostAvailability = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (values) => {
      const data = await postData('availability', values)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['availability'])
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useDeleteAvailability = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (id) => {
      const data = await deleteData(`availability/${id}`)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['availability'])
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useGetAvailability = (id) => {
  return useQuery({
    queryKey: ['availability'],
    queryFn: async () => {
      const data = await getData(`availability/${id}`)
      const formattedData = data.map((item) => {
        return {
          ...item,
          date: new Date(item.date),
        }
      })
      formattedData.sort((a, b) => a.date - b.date)
      return formattedData
    },
  })
}

export const useUpdateAvailability = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (values) => {
      const data = await putData('availability', values)
      return data
    },
    onSuccess: () => {
      notifySuccess('Disponibilidad actualizada')
      queryClient.invalidateQueries(['availability'])
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useFilterSitters = () => {
  const { data: user } = useGetProfile()
  const userId = user?.id
  const { selectedAddress, setIsAddressModalOpen } = useFilter()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (body) => {
      userId ? (body.userId = userId) : null
      if (!selectedAddress) {
        setIsAddressModalOpen(true)
        return Promise.reject(new Error('Debes seleccionar una dirección'))
      }
      const data = await postData('availability/filter', body)
      return data
    },
    onSuccess: (data) => {
      dispatch(setSitters(data))
      navigate('/sitter-selection')
      queryClient.invalidateQueries(['sitterData'])
    },
    onError: (error) => {
      notifyError(error.message)
    },
    onSettled: (variables) => {
      const filter = {
        type: variables.type,
        commune: variables.commune,
        dates: variables.dates,
      }
      dispatch(saveFilter(filter))
    },
  })
}
