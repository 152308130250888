/* eslint-disable react/prop-types */
import { useGetProfile } from '@services/user'
import { useState } from 'react'

import UpdateBankData from '@common/forms/UpdateBankData'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import { useUpdateUser } from '@services/user'

function BankData() {
  const { data: user } = useGetProfile()
  const { mutate: updateUser } = useUpdateUser()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <h2 className="font-tex-bold text-base font-semibold leading-7 text-gray-900">
        Datos Bancarios
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-500">
        ¡Estos son los datos de tu banco! 🏦 Donde te depositaremos tus
        ganancias. 💵
      </p>

      {user.bankAccount ? (
        <ul
          role="list"
          className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6"
        >
          <li className="flex justify-between gap-x-6 py-6">
            <div className="font-medium text-gray-900">
              {user?.bankAccount?.bank}
              <span className="ml-3 text-sm text-grey-soft">
                {user?.bankAccount?.type}
              </span>
            </div>
            <div className="space-x-6 space-y-2">
              <button
                type="button"
                onClick={() => setIsOpen(true)}
                className="font-semibold text-blue-soft hover:text-blue-600"
              >
                Actualizar
              </button>
              <button
                type="button"
                onClick={() => updateUser({ bankAccount: null })}
                className="font-semibold text-red-base hover:text-red-600"
              >
                Eliminar
              </button>
            </div>
          </li>
        </ul>
      ) : (
        <div className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <button
            type="button"
            onClick={() => setIsOpen(true)}
            className="py-6 text-sm font-semibold leading-6 text-blue-soft hover:text-blue-600"
          >
            <span aria-hidden="true">+</span> Agrega tu banco
          </button>
        </div>
      )}
      <CustomModal
        header={
          <ModalHeader
            title="Actualizar Datos Bancarios"
            subtitle="Indícanos donde quieres que transfiramos tus ganancias 💵"
          />
        }
        body={<UpdateBankData onSubmit={() => setIsOpen(false)} />}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </div>
  )
}

// eslint-disable-next-line react/prop-types

export default BankData
