import { Navigate, Outlet } from 'react-router-dom'
import { useGetProfile } from '@services/user'
import { useContext } from 'react'
import { AuthContext } from '@common/context/AuthContext'

const AdminRoutes = () => {
  const { getAuthToken } = useContext(AuthContext)
  const {data: user} = useGetProfile()
  return getAuthToken() && user?.isSitter ? <Outlet /> : <Navigate to="/" />
}

export default AdminRoutes
