import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useGetProfile } from '@services/user'
import { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AiOutlinePlus } from 'react-icons/ai'
import { classNames } from '@utils/formatFunctions'
import { postData } from '@services/requests'
import ErrorNoCard from '@common/error/ErrorNoCard'

function UploadPhotosModal({ isOpen, onClose, headerUser, record, chatId }) {
  const modalClass = isOpen
    ? 'fixed inset-0 flex items-center justify-center'
    : 'hidden'
  const { data: user } = useGetProfile()
  const [photos, setPhotos] = useState([])
  const queryClient = useQueryClient()

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files)
    setPhotos((prevPhotos) => [...prevPhotos, ...files])
  }

  const {
    isPending: postIsPending,
    isError: postIsError,
    error: postError,
    mutate: postImageMessage,
  } = useMutation({
    mutationFn: async (formData) => {
      const data = await postData(
        `chat/${user.id}/${chatId}`,
        formData,
        { 'Content-Type': 'multipart/form-data' },
      )
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['chat', record.id] })
      onClose()
    },
  })

  const handlePostPhotos = async () => {
    for (const photo of photos) {
      const formData = new FormData()
      formData.append('type', 'photo')
      formData.append('image', photo)
      formData.append('content', '')
      await postImageMessage(formData)
    }
  }

  return (
    <div className={`${modalClass} z-50`}>
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="relative z-10 m-2 w-full space-y-4 rounded-xl bg-white p-6 shadow-lg sm:max-w-lg">
        <button
          onClick={onClose}
          className="absolute right-2 top-2 flex h-10 w-10 items-center justify-center rounded-full p-1 text-2xl"
        >
          &times;
        </button>
        <div className="space-y-1">
          <h2 className="font-tex text-xl text-grey-base sm:text-2xl">
            Subir Fotos
          </h2>
          <p className="text-xs text-grey-soft sm:text-base">
            Selecciona las fotos que quieras compartir con {headerUser.name}
          </p>
        </div>

        <Swiper
          spaceBetween={16}
          slidesPerView={'auto'}
          freeMode={true}
          scrollbar={{ draggable: true }}
          className="swiper-container"
        >
          <SwiperSlide style={{ width: 'auto' }}>
            <div className="relative flex h-32 w-32 items-center justify-center rounded-lg border-2 bg-grey-soft hover:bg-grey-sec">
              <input
                id="photos"
                type="file"
                multiple
                onChange={handleFileChange}
                className="absolute inset-0 h-full w-full cursor-pointer opacity-0"
              />
              <AiOutlinePlus className="text-4xl text-grey-base" />
            </div>
          </SwiperSlide>
          {photos.map((photo, index) => (
            <SwiperSlide key={index} style={{ width: 'auto' }}>
              <div
                onClick={() => {
                  photos.splice(index, 1)
                  setPhotos([...photos])
                }}
                className="relative h-32 w-32 cursor-pointer"
              >
                <div className="absolute inset-0 rounded-lg bg-black opacity-0 transition-all duration-300 ease-in-out hover:opacity-50" />
                <img
                  src={URL.createObjectURL(photo)}
                  alt={`uploaded photo ${index + 1}`}
                  className="h-full w-full rounded-lg object-cover"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="flex w-full justify-end">
          <button
            onClick={handlePostPhotos}
            className={classNames(
              'flex w-[30%] flex-row items-center justify-center gap-2 rounded-lg py-2 text-sm text-white hover:opacity-80 focus:outline-none',
              record.type == 'dogWalking' && 'bg-yellow-sec',
              record.type == 'visit' && 'bg-blue-ter',
              record.type == 'dayCare' && 'bg-purple-base',
            )}
          >
            {postIsPending ? (
              <div className="h-5 w-5 animate-spin rounded-full border-2 border-t-white border-opacity-80" />
            ) : (
              'Compartir'
            )}
          </button>
        </div>
      </div>
      {postIsError && <ErrorNoCard message={postError.message} />}
    </div>
  )
}

UploadPhotosModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  headerUser: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  chatId: PropTypes.string.isRequired,
}

export default UploadPhotosModal
