import { useForm } from '@tanstack/react-form'
import * as Yup from 'yup'
import { yupValidator } from '@tanstack/yup-form-adapter'
import { useSignUp } from '@services/auth'
import FormInput from '@common/inputs/FormInput'
import FormPassword from '../inputs/FormPassword'
import FormButton from '@common/buttons/FormButton'
import FieldError from '@common/error/FieldError'

const validations = {
  name: Yup.string().required('El nombre es obligatorio'),
  lastName: Yup.string().required('El apellido es obligatorio'),
  rut: Yup.string()
    .matches(/^\d{1,2}\.\d{3}\.\d{3}-[\dkK]$/, 'El RUT debe ser válido')
    .required('El RUT es obligatorio'),
  email: Yup.string()
    .email('Debe ser una dirección de correo electrónico válida')
    .required('El correo electrónico es obligatorio'),
  phone: Yup.string()
    .matches(
      /^9(\s*\d{4}\s*\d{4}|\d{4}\s*\d{4}|\s*\d{6})$/,
      'El teléfono debe contener solo números',
    )
    .required('El teléfono es obligatorio'),
  password: Yup.string()
    .min(8, 'Debe tener al menos 8 caracteres')
    .matches(/[A-Z]/, 'Debe contener mayúsculas')
    .required('Ups! Parece que olvidaste decirnos tu contraseña.'),
  confirmPassword: Yup.string().required(
    'La confirmación de contraseña es obligatoria',
  ),
  acceptTerms: Yup.boolean()
    .oneOf([true], 'Debes aceptar los términos y condiciones')
    .required('Debes aceptar los términos y condiciones'),
}

function SignUpForm() {
  const { mutate: signUp, isPending } = useSignUp()

  const form = useForm({
    onSubmit: ({ value }) => {
      signUp(value)
    },
    validatorAdapter: yupValidator(),
    defaultValues: {
      name: '',
      lastName: '',
      rut: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      acceptTerms: false,
    },
  })

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        form.handleSubmit()
      }}
      className="space-y-4"
    >
      <form.Field name="name" validators={{ onChange: validations.name }}>
        {(field) => (
          <FormInput
            field={field}
            name="name"
            label="Nombre"
            placeholder="Escribe tu nombre"
          />
        )}
      </form.Field>
      <form.Field
        name="lastName"
        validators={{ onChange: validations.lastName }}
      >
        {(field) => (
          <FormInput
            field={field}
            name="lastName"
            label="Apellido"
            placeholder="Escribe tu apellido"
          />
        )}
      </form.Field>
      <form.Field name="rut" validators={{ onChange: validations.rut }}>
        {(field) => (
          <FormInput
            field={field}
            name="rut"
            label="RUT"
            placeholder="Escribe tu RUT"
            isRut
          />
        )}
      </form.Field>
      <form.Field name="email" validators={{ onChange: validations.email }}>
        {(field) => (
          <FormInput
            field={field}
            name="email"
            label="Correo electrónico"
            placeholder="Escribe tu correo electrónico"
          />
        )}
      </form.Field>
      <form.Field name="phone" validators={{ onChange: validations.phone }}>
        {(field) => (
          <FormInput
            field={field}
            name="phone"
            label="Teléfono"
            placeholder="Escribe tu teléfono"
            isPhone
          />
        )}
      </form.Field>
      <form.Field
        name="password"
        validators={{ onChange: validations.password }}
      >
        {(field) => (
          <FormPassword
            field={field}
            name="password"
            label="Contraseña"
            placeholder="Escribe tu contraseña"
            type="password"
          />
        )}
      </form.Field>
      <form.Field
        name="confirmPassword"
        validators={{
          onChangeListenTo: ['password'],
          onChange: ({ value, fieldApi }) => {
            if (value !== fieldApi.form.getFieldValue('password')) {
              return 'Las contraseñas no coinciden'
            }
            return undefined
          },
        }}
      >
        {(field) => (
          <FormPassword
            field={field}
            name="confirmPassword"
            label="Confirmar contraseña"
            placeholder="Confirma tu contraseña"
            type="password"
          />
        )}
      </form.Field>
      <form.Field
        name="acceptTerms"
        validators={{ onChange: validations.acceptTerms }}
      >
        {(field) => (
          <div className="space-y-2">
            <div className="flex flex-row items-center gap-2">
              <input
                id="acceptTerms"
                name="acceptTerms"
                value={field.state.value ?? false}
                type="checkbox"
                onChange={() => field.handleChange(!field.state.value)}
                className="ml-1 h-4 w-4 rounded-sm accent-blue-soft"
              />
              <label
                htmlFor="acceptTerms"
                className="block text-xs leading-6 text-grey-base"
              >
                Acepto los términos y condiciones
              </label>
            </div>

            {field.state.meta.isTouched && field.state.meta.errors.length ? (
              <FieldError message={field.state.meta.errors.join(', ')} />
            ) : null}
          </div>
        )}
      </form.Field>
      <form.Subscribe
        selector={(state) => [state.canSubmit, state.isSubmitting]}
      >
        {([canSubmit, isSubmitting]) => (
          <FormButton
            theme="blue"
            isPending={isSubmitting || isPending}
            isDisabled={!canSubmit}
            text={'Registrarse'}
          />
        )}
      </form.Subscribe>
    </form>
  )
}

export default SignUpForm
