/* eslint-disable no-unused-vars */
import UserSummary from './components/profile/UserSummary'
import Pets from './components/profile/Pets'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useGetProfile } from '@services/user'
import Loading from '@common/utils/Loading'

function Profile() {
  const { data: user, isPending } = useGetProfile()

  if (isPending) return <Loading />

  return (
    <div className="flex flex-col gap-4 p-4 md:h-screen">
      <ToastContainer position="top-center" />
      <div className="container mx-auto flex flex-col gap-4 p-2 sm:flex-row">
        <div className="sm:w-1/3">
          <UserSummary />
        </div>
        <div className="sm:w-2/3">
          <Pets />
        </div>
      </div>
    </div>
  )
}

export default Profile
