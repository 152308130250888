import { useForgetPassword } from '@services/auth'
import { ToastContainer } from 'react-toastify'
import FormButton from '@common/buttons/FormButton'

export default function ForgetPassword() {
  const { isPending, mutate } = useForgetPassword()
  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const email = e.target.email.value
    mutate({ email })
  }

  return (
    <>
      <ToastContainer />
      <div className="mx-auto my-8 sm:min-h-screen">
        <form
          onSubmit={handleSubmit}
          className="flex min-w-[400px] flex-col gap-2 rounded-lg bg-white p-4 shadow-md"
        >
          <h1 className="mb-4 font-tex text-2xl font-bold text-grey-base">
            Restablece tu contraseña
          </h1>
          <label
            htmlFor="email"
            className="block font-mont-semi text-sm leading-6 text-grey-base"
          >
            Correo Electrónico
          </label>
          <input
            title="Correo Electrónico"
            name="email"
            type="email"
            placeholder="Escribe tu correo electrónico"
            className="border-1 focus:border-1 w-full rounded-lg border-grey-soft bg-white py-1.5 pl-3 text-xs text-grey-base placeholder-grey-soft shadow-sm focus:border-grey-base focus:ring-1 focus:ring-blue-base focus:ring-offset-2 sm:leading-6"
          />
          <FormButton
            text="Enviar link de restablecimiento"
            theme="blue"
            isPending={isPending}
            isDisabled={isPending}
          />
        </form>
      </div>
    </>
  )
}
