import PropTypes from 'prop-types'
import { useForm } from '@tanstack/react-form'
import { yupValidator } from '@tanstack/yup-form-adapter'
import * as yup from 'yup'
import FormButton from '@common/buttons/FormButton'
import FormInput from '@common/inputs/FormInput'
import FormSelect from '@common/inputs/FormSelect'
import { useUpdateUser, useGetProfile } from '@services/user'
import { BANKS } from '@assets/constants'

const validations = {
  bank: yup.string().required('Debes ingresar un banco'),
  type: yup.string().required('Debes ingresar un tipo de cuenta'),
  accountNumber: yup.string().required('Debes ingresar un número de cuenta'),
}

const TYPES = [
  { id: 1, value: 'Cuenta Corriente' },
  { id: 2, value: 'Cuenta Vista' },
  { id: 3, value: 'Cuenta de Ahorro' },
]

function UpdateBankData({ onSubmit }) {
  const { data: user } = useGetProfile()
  const { isPending, mutate: updateUser } = useUpdateUser()

  const form = useForm({
    onSubmit: ({ value }) => {
      updateUser({ bankAccount: { ...value } })
      onSubmit()
    },
    validatorAdapter: yupValidator(),
    defaultValues: {
      bank: user?.bankAccount?.bank ?? '',
      type: user?.bankAccount?.type ?? '',
      accountNumber: user?.bankAccount?.accountNumber ?? '',
    },
  })

  return (
    <form
      className="space-y-4"
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        form.handleSubmit()
      }}
    >
      <form.Field
        name="bank"
        validators={{
          onChange: validations.bank,
        }}
      >
        {(field) => (
          <FormSelect
            data={BANKS.map((bank, index) => ({ id: index, value: bank }))}
            field={field}
            name="bank"
            label="Banco"
          />
        )}
      </form.Field>
      <form.Field
        name="type"
        validators={{
          onChange: validations.type,
        }}
      >
        {(field) => (
          <FormSelect
            data={TYPES}
            field={field}
            name="type"
            label="Tipo de Cuenta"
          />
        )}
      </form.Field>
      <form.Field
        name="accountNumber"
        validators={{
          onChange: validations.accountNumber,
        }}
      >
        {(field) => (
          <FormInput
            field={field}
            name="accountNumber"
            label="Numero de cuenta"
          />
        )}
      </form.Field>

      <form.Subscribe
        selector={(state) => [state.canSubmit, state.isSubmitting]}
      >
        {([canSubmit, isSubmitting]) => (
          <FormButton
            theme="blue"
            isPending={isSubmitting || isPending}
            isDisabled={!canSubmit}
            text={'Actualiza tus datos'}
          />
        )}
      </form.Subscribe>
    </form>
  )
}

UpdateBankData.propTypes = {
  onSubmit: PropTypes.func,
}

export default UpdateBankData
