import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getData } from '@services/requests'
import SitterProfile from './components/admin_sitter_stats/SitterProfile'
import SitterStats from './components/admin_sitter_stats/SitterStats'
import Debt from './components/admin_sitter_stats/Debt'
import Loading from '@common/utils/Loading'
import Error from '@common/error/Error'
import useFilter from '@zustand/filter'

function AdminSitterStats() {
  const { sitterId } = useParams()
  const { selectedAddress } = useFilter()

  const {
    isPending: isPendingSitter,
    isError: isErrorSitter,
    data: sitter,
  } = useQuery({
    queryKey: ['adminSitter', sitterId],
    queryFn: () =>
      getData(
        `user/sitter-info/${sitterId}?selectedAddress=${selectedAddress.geometry.location.lat},${selectedAddress.geometry.location.lng}`,
      ),
  })

  const {
    isPending: isPendingStats,
    isError: isErrorStats,
    data: stats,
  } = useQuery({
    queryKey: ['adminSitterStats', sitterId],
    queryFn: () => getData('service-record/sitter-stats'),
  })

  const {
    isPending: isPendingDebt,
    isError: isErrorDebt,
    data: debt,
  } = useQuery({
    queryKey: ['adminSitterDebt', sitterId],
    queryFn: () => getData(`service-record/debt/${sitterId}`),
  })

  if (isPendingStats || isPendingDebt || isPendingSitter) return <Loading />

  if (isErrorStats || isErrorDebt || isErrorSitter)
    return <Error message="Problemas cargando los datos." />

  return (
    <div className="flex flex-col gap-2 p-2 sm:flex-row sm:p-4">
      <div className="space-y-2 sm:w-[40%]">
        <SitterProfile sitter={sitter} stats={stats} />
        <Debt sitter={sitter} debtData={debt} />
      </div>
      <div className="sm:w-[60%]">
        <SitterStats sitter={sitter} stats={stats} />
      </div>
    </div>
  )
}

export default AdminSitterStats
