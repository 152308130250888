import PropTypes from 'prop-types'
import { useForm } from '@tanstack/react-form'
import * as Yup from 'yup'
import { yupValidator } from '@tanstack/yup-form-adapter'
import FormInput from '@common/inputs/FormInput'
import FormButton from '@common/buttons/FormButton'

const validations = {
  name: Yup.string().required('El nombre es obligatorio'),
  lastName: Yup.string().required('El apellido es obligatorio'),
  rut: Yup.string()
    .matches(/^\d{1,2}\.\d{3}\.\d{3}-[\dkK]$/, 'El RUT debe ser válido')
    .required('El RUT es obligatorio'),
  email: Yup.string()
    .email('Debe ser una dirección de correo electrónico válida')
    .required('El correo electrónico es obligatorio'),
  phone: Yup.string()
    .matches(
      /^9(\s*\d{4}\s*\d{4}|\d{4}\s*\d{4}|\s*\d{6})$/,
      'El teléfono debe contener solo números',
    )
    .required('El teléfono es obligatorio'),
  password: Yup.string()
    .min(8, 'La contraseña debe tener al menos 8 caracteres')
    .required('La contraseña es obligatoria'),
  acceptTerms: Yup.boolean()
    .oneOf([true], 'Debes aceptar los términos y condiciones')
    .required('Debes aceptar los términos y condiciones'),
}

export default function UserForm({ onClose }) {

  const form = useForm({
    onSubmit: () => {
      onClose()
    },
    validatorAdapter: yupValidator(),
    defaultValues: {
      name: '',
      lastName: '',
      rut: '',
      email: '',
      phone: '',
      password: '',
      acceptTerms: true,
    },
  })

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        form.handleSubmit()
      }}
      className="space-y-4"
    >
      <form.Field name="name" validators={{ onChange: validations.name }}>
        {(field) => (
          <FormInput
            field={field}
            name="name"
            label="Nombre"
            placeholder="Escribe el nombre"
          />
        )}
      </form.Field>
      <form.Field
        name="lastName"
        validators={{ onChange: validations.lastName }}
      >
        {(field) => (
          <FormInput
            field={field}
            name="lastName"
            label="Apellido"
            placeholder="Escribe el apellido"
          />
        )}
      </form.Field>
      <form.Field name="rut" validators={{ onChange: validations.rut }}>
        {(field) => (
          <FormInput
            field={field}
            name="rut"
            label="RUT"
            placeholder="Escribe el RUT"
            isRut
          />
        )}
      </form.Field>
      <form.Field name="email" validators={{ onChange: validations.email }}>
        {(field) => (
          <FormInput
            field={field}
            name="email"
            label="Correo electrónico"
            placeholder="Escribe el correo electrónico"
          />
        )}
      </form.Field>
      <form.Field name="phone" validators={{ onChange: validations.phone }}>
        {(field) => (
          <FormInput
            field={field}
            name="phone"
            label="Teléfono"
            placeholder="Escribe el teléfono"
            isPhone
          />
        )}
      </form.Field>
      <form.Field
        name="password"
        validators={{ onChange: validations.password }}
      >
        {(field) => (
          <FormInput
            field={field}
            name="password"
            label="Contraseña"
            placeholder="Escribe la contraseña"
          />
        )}
      </form.Field>
      <form.Subscribe
        selector={(state) => [state.canSubmit, state.isSubmitting]}
      >
        {([canSubmit, isSubmitting]) => (
          <FormButton
            theme="blue"
            isPending={isSubmitting }
            isDisabled={!canSubmit}
            text={'Crear Usuario'}
          />
        )}
      </form.Subscribe>
    </form>
  )
}

UserForm.propTypes = {
  onClose: PropTypes.func.isRequired,
}
