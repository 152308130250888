import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { putData, deleteData, getData, postData } from '@services/requests'
import { toast } from 'react-toastify'
import { AuthContext } from '@common/context/AuthContext'
import useFilter from '@zustand/filter'
import 'react-toastify/dist/ReactToastify.css'

const notifySuccess = (message) => toast.success(`${message} 🎉`)
const notifyError = (message) => toast.error(`Error: ${message} 😢`)

export const useUpdateUser = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (values) => {
      const data = await putData('user', values)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] })
      notifySuccess('Datos actualizados correctamente')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useDeleteUser = () => {
  const { removeAuthToken } = useContext(AuthContext)
  return useMutation({
    mutationFn: async () => {
      const data = await deleteData('user')
      return data
    },
    onSuccess: () => {
      removeAuthToken()
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useGetProfilePhotos = () => {
  return useQuery({
    queryKey: ['profilePhotos'],
    queryFn: () => getData('user/profile-photos'),
  })
}

export const useUpdateProfilePhotos = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (body) =>
      await postData('user/update-profile-photos', body, {
        'Content-Type': 'multipart/form-data',
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profilePhotos'] })
    },
  })
}

export const useUpdateFavoriteSitter = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (values) => {
      const data = await putData('user/change-favorite', {
        sitterId: values.id,
      })
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sitters'] })
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useGetSitters = () => {
  return useQuery({
    queryKey: ['sitters'],
    queryFn: () => getData('user/sitters'),
  })
}

export const useGetSitterInfo = (sitterId) => {
  const { selectedAddress } = useFilter()
  return useQuery({
    queryKey: ['sitterData', sitterId],
    enabled: !!sitterId,
    queryFn: () =>
      getData(
        `user/sitter-info/${sitterId}?selectedAddress=${selectedAddress?.geometry?.location?.lat},${selectedAddress?.geometry?.location?.lng}`,
      ),
  })
}

export const useGetProfile = () => {
  const { getAuthToken } = useContext(AuthContext)
  return useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      const response = await getData('user/profile')
      const photoUrl =
        response?.photoUrl ??
        'https://api.dicebear.com/9.x/initials/png?seed=${response.name}${response.lastName}'
      return { ...response, photoUrl }
    },
    enabled: !!getAuthToken(),
  })
}
