import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { postData } from '@services/requests'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'

import 'react-toastify/dist/ReactToastify.css'

const notifySuccess = (message) => toast.success(`${message} 🎉`)
const notifyError = (message) => toast.error(`Error: ${message} 😢`)

export const useCreateMpPayment = (handleSuccess) => {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async (data) => {
      const body = {
        formData: data.formData,
        price: data.paymentAmount,
        priceBeforeDiscount:
          data.priceBeforeDiscount > 0 ? data.priceBeforeDiscount : null,
      }
      return await postData('payment/mercadopago/create', body)
    },

    onSettled: (data) => {
      navigate('/payment/mercadopago/status', {
        state: { paymentId: data?.mercadopago?.id },
      })
    },

    onSuccess: (data) => {
      handleSuccess(data?.id)
    },
  })
}

export const useCreateWpTransaction = () => {
  return useMutation({
    mutationFn: async (body) => {
      const data = await postData('payment/webpay/create', body)
      return data
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useCommitWpTransaction = (handleSuccess) => {
  return useMutation({
    queryKey: ['commitWp'],
    mutationFn: async (payload) => {
      const body = {
        tokenWs: payload.tokenWs,
        price: payload.price,
        priceBeforeDiscount:
          payload.priceBeforeDiscount > 0 ? payload.priceBeforeDiscount : null,
      }
      const data = await postData('payment/webpay/commit', body)
      return data
    },
    onSuccess: (data) => {
      handleSuccess(data?.id)
      return data
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useCreateOcInscription = () => {
  return useMutation({
    mutationFn: async (body) => {
      const data = await postData(
        'payment/create-oc-inscription',
        body,
      )
      return data
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useCommitOcInscription = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (body) => {
      const data = await postData(
        'payment/commit-oc-inscription',
        body,
      )
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] })
      notifySuccess('Inscripción exitosa ✅')
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useAuthorizeOcInscription = (handleSuccess) => {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async (body) => {
      const data = await postData(
        `payment/authorize-oc-transaction/${body.serviceRecordInfo.sitterId}`,
        body,
      )
      return data
    },
    onSuccess: (data) => {
      notifySuccess('Transacción exitosa ✅')
      handleSuccess(data?.id)
      navigate('/hire-sitter/transbank-oc-commit', {
        state: data.payment,
      })
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}

export const useCalculatePrice = () => {
  return useMutation({
    mutationFn: async (body) => {
      const data = await postData('payment/calculate-price', body)
      return data
    },
    onError: (error) => {
      notifyError(error.message)
    },
  })
}
