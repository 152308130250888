/* eslint-disable react/prop-types */
import { useGetProfile } from '@services/user'
import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { postData } from '@services/requests'
import FormButton from '@common/buttons/FormButton'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'

import CustomModal from '@common/layouts/CustomModal'

function ProfilePhoto() {
  const { data: user } = useGetProfile()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <img
        src={user?.photoUrl}
        onClick={() => setIsOpen(true)}
        alt="Foto de perfil"
        className="mx-auto h-44 w-44 flex-1 cursor-pointer rounded-full bg-white p-1.5 shadow-xl hover:opacity-90"
      />

      <CustomModal
        header={
          <div className="space-y-2">
            <p className="font-tex-bold text-lg text-grey-base">
              Actualiza tu foto de perfil
            </p>
            <p className="text-xs text-grey-sec">
              Cambia tu foto de perfil para destacar en la comunidad! 📸✨
            </p>
          </div>
        }
        body={<ModalBody user={user} onClose={() => setIsOpen(false)} />}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  )
}

const ModalBody = ({ onClose }) => {
  const queryClient = useQueryClient()
  const [file, setFile] = useState(null)
  const [image, setImage] = useState(null)

  const { isPending: postIsPending, mutate: postProfilePhoto } = useMutation({
    mutationFn: async (formData) => {
      const data = await postData('user/main-photo', formData, {
        'Content-Type': 'multipart/form-data',
      })
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] })
      setFile(null)
      setImage(null)
      onClose()
      toast.success('Foto de perfil actualizada correctamente 🎉')
    },
    onError: (error) => {
      toast.error(`Error: ${error.message} 😢 `)
    },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const formData = new FormData()
    formData.append('image', file)
    postProfilePhoto(formData)
  }

  const handleFileChange = (event) => {
    const selectedImage = event.target.files[0]
    setFile(selectedImage)
    if (selectedImage) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setImage(reader.result)
      }
      reader.readAsDataURL(selectedImage)
    }
  }

  return (
    <form onSubmit={(e) => handleSubmit(e)} className="space-y-8">
      <div className="mx-auto mt-2 flex h-40 w-40 items-center justify-center rounded-full border-2 border-dashed border-gray-300 hover:bg-gray-100 hover:opacity-90">
        <label
          htmlFor="photo"
          className="absolute z-10 h-40 w-40 rounded-full"
        ></label>
        {image && (
          <img
            src={image}
            alt="Foto de perfil"
            className="absolute h-44 w-44 rounded-full bg-white object-cover p-1.5 shadow-xl"
          />
        )}
        <input
          id="photo"
          name="photo"
          type="file"
          className="sr-only"
          onChange={handleFileChange}
        />
      </div>
      <FormButton
        theme="blue"
        isPending={postIsPending}
        isDisabled={!file}
        text={'Guardar!'}
      />
    </form>
  )
}

export default ProfilePhoto
