import { useState } from 'react'
import {
  CreditCardIcon,
  HomeIcon,
  UserCircleIcon,
  FingerPrintIcon,
} from '@heroicons/react/24/outline'
import { ToastContainer } from 'react-toastify'
import { useGetProfile } from '@services/user'

import PersonalInfo from './components/settings/PersonalInfo'
import ProfilePhoto from './components/settings/ProfilePhoto'
import Addresses from './components/settings/Addresses'
import BankData from './components/settings/BankData'
import Cards from './components/settings/Cards'
import Security from './components/settings/Security'

const navigation = [
  { index: 0, name: 'General', icon: UserCircleIcon },
  { index: 1, name: 'Direcciones', icon: HomeIcon },
  { index: 2, name: 'Tarjetas', icon: CreditCardIcon },
  { index: 3, name: 'Seguridad', icon: FingerPrintIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Settings() {
  const [tabIndex, setTabIndex] = useState(0)
  const { data: user } = useGetProfile()

  return (
    <>
      <div className="lg:mx-auto lg:flex lg:w-[75%] lg:gap-x-16 lg:px-8 lg:py-8">
        <ToastContainer position="top-center" />
        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul
              role="list"
              className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col"
            >
              {navigation.map((item) => (
                <li key={item.name}>
                  <button
                    onClick={() => setTabIndex(item.index)}
                    className={classNames(
                      tabIndex === item.index
                        ? 'bg-grey-soft text-blue-soft'
                        : 'text-gray-700 hover:bg-grey-soft hover:text-blue-soft',
                      'group flex w-full gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6',
                    )}
                  >
                    <item.icon
                      aria-hidden="true"
                      className={classNames(
                        item.current
                          ? 'text-blue-soft'
                          : 'text-gray-400 group-hover:text-blue-soft',
                        'h-6 w-6 shrink-0',
                      )}
                    />
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            {tabIndex === 0 && (
              <div className="space-y-4">
                <ProfilePhoto />
                <div className="space-y-12">
                  <PersonalInfo />
                  {user?.isSitter && <BankData />}
                </div>
              </div>
            )}
            {tabIndex === 1 && <Addresses />}
            {tabIndex === 2 && <Cards />}
            {tabIndex === 3 && <Security />}
          </div>
        </main>
      </div>
    </>
  )
}
