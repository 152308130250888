/* eslint-disable react/prop-types */
import { useGetProfile } from '@services/user'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import { useState } from 'react'
import { Radio, RadioGroup } from '@headlessui/react'
import { useUpdateUser } from '@services/user'

const getInitialPrice = (servicePrices, userPrice) => {
  const matchedOption = servicePrices.find(
    (option) => option.price === userPrice,
  )
  return matchedOption
}

const dogWalkingOptions = [
  { id: 'dogWalking0', price: 6000 },
  { id: 'dogWalking1', price: 8000 },
  { id: 'dogWalking2', price: 10000 },
  { id: 'dogWalking3', price: null },
]

const visitOptions = [
  { id: 'visit0', price: 12000 },
  { id: 'visit1', price: 15000 },
  { id: 'visit2', price: 18000 },
  { id: 'visit3', price: null },
]

const dayCareOptions = [
  { id: 'dayCare0', price: 25000 },
  { id: 'dayCare1', price: 30000 },
  { id: 'dayCare2', price: 35000 },
  { id: 'dayCare3', price: null },
]

function Prices() {
  const { data: user } = useGetProfile()
  const [isOpen, setIsOpen] = useState(false)
  const [dogWalking, setDogWalking] = useState(
    getInitialPrice(dogWalkingOptions, user?.prices?.dogWalking),
  )
  const [visit, setVisit] = useState(
    getInitialPrice(visitOptions, user?.prices?.visit),
  )
  const [dayCare, setDayCare] = useState(
    getInitialPrice(dayCareOptions, user?.prices?.dayCare),
  )
  const { mutate: updateUser } = useUpdateUser()

  const prices = [
    {
      title: 'Paseo',
      subtitle: '(CLP) por paseo 🦮',
      description: 'Paseo de entre 40 y 60 minutos',
      value: user?.prices?.dogWalking,
      className:
        'cursor-pointer items-center justify-center rounded-full bg-yellow-50 px-6 py-2 text-xs text-slate-900 ring-1 ring-yellow-sec hover:bg-yellow-100 focus:outline-none data-[checked]:bg-yellow-base data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-yellow-sec data-[focus]:ring-offset-2 [&:not([data-focus],[data-checked])]:ring-inset',
      options: dogWalkingOptions,
      mem: dogWalking,
      setMem: setDogWalking,
    },
    {
      title: 'Visita',
      subtitle: '(CLP) por visita 🏡',
      description: 'Cuidas a la mascota del cliente en su casa',
      value: user?.prices?.visit,
      className:
        'cursor-pointer items-center justify-center rounded-full bg-blue-50 px-6 py-2 text-xs text-slate-900 ring-1 ring-blue-ter hover:bg-blue-100 focus:outline-none data-[checked]:bg-blue-ter data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-blue-ter data-[focus]:ring-offset-2 [&:not([data-focus],[data-checked])]:ring-inset',
      options: visitOptions,
      mem: visit,
      setMem: setVisit,
    },
    {
      title: 'Alojamiento',
      subtitle: '(CLP) por noche 🌙',
      description: 'Cuidas a la mascota del cliente en tu casa',
      value: user?.prices?.dayCare,
      className:
        'cursor-pointer items-center justify-center rounded-full bg-purple-50 px-6 py-2 text-xs text-slate-900 ring-1 ring-purple-base hover:bg-purple-100 focus:outline-none data-[checked]:bg-purple-base data-[checked]:text-white data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-purple-sec data-[focus]:ring-offset-2 [&:not([data-focus],[data-checked])]:ring-inset',
      options: dayCareOptions,
      mem: dayCare,
      setMem: setDayCare,
    },
  ]

  const onClose = () => {
    setIsOpen(false)
    const body = {
      prices: {
        dogWalking: dogWalking?.price ?? null,
        visit: visit?.price ?? null,
        dayCare: dayCare?.price ?? null,
      },
    }
    if (
      body.prices.dogWalking !== user.prices.dogWalking ||
      body.prices.visit !== user.prices.visit ||
      body.prices.dayCare !== user.prices.dayCare
    ) {
      updateUser(body)
    }
  }

  return (
    <>
      <div className="space-y-4">
        <div className="flex flex-row items-center gap-2 font-tex text-xl">
          <h1>Precios</h1>
          <p>💵</p>
        </div>
        <div
          className="grid grid-cols-1 gap-3 sm:grid-cols-3"
          onClick={() => setIsOpen(true)}
        >
          {prices.map((price, index) => (
            <div
              className="flex cursor-pointer flex-col items-start space-y-2 transition-transform hover:scale-105"
              key={index}
            >
              <h2 className="flex flex-col text-sm font-bold text-grey-base xl:flex-row">
                {price.title}
                <span className="text-[0.5rem] text-grey-sec xl:ml-1">
                  {price.subtitle}
                </span>
              </h2>
              <div className="flex h-32 w-full flex-col items-center justify-center space-y-4 rounded-md border border-grey-sec p-2 text-xs">
                {price.value ? (
                  <>
                    <div className="flex w-full flex-row justify-between text-slate-900">
                      <p>Precio</p>
                      <p>{`$${price.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`}</p>
                    </div>
                    <div className="flex w-full flex-row justify-between text-grey-sec">
                      <p>Fee (20%)</p>
                      <p className="text-red-base">{`- $${(price.value * 0.2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`}</p>
                    </div>
                    <hr className="my-2 w-full bg-grey-soft" />
                    <div className="flex w-full flex-row justify-between font-bold text-slate-900">
                      <p>Recibes</p>
                      <p>{`$${(price.value * 0.8)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="text-center text-xs font-semibold text-slate-900">
                      No ofreces este servicio
                    </p>
                    <p className="text-center text-[0.6rem] text-grey-sec">
                      Puedes actualizar el precio de tus servicios cuando
                      quieras 💸
                    </p>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <CustomModal
        header={
          <ModalHeader
            title="Actualiza tus precios"
            subtitle="Recuerda que el 20% de tus precios se descuenta por el fee de Pets-Okey"
          />
        }
        body={<ModalBody prices={prices} onClose={onClose} />}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

const ModalBody = ({ prices, onClose }) => {
  return (
    <div className="flex flex-col gap-12">
      {prices.map((price, index) => (
        <fieldset aria-label="Choose a price option" key={index}>
          <h2 className="flex flex-col text-base font-bold text-grey-base sm:flex-row sm:gap-1">
            {price.title}
            <span className="text-[0.6rem] text-grey-sec sm:ml-1">
              {price.description}
            </span>
          </h2>

          <RadioGroup
            value={price.mem}
            onChange={price.setMem}
            className="mt-6 flex flex-wrap items-center gap-6"
          >
            {price.options.map((option) => (
              <Radio key={option.id} value={option} className={price.className}>
                {option?.price
                  ? `$${option.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
                  : 'No ofrecer este servicio'}
              </Radio>
            ))}
          </RadioGroup>
        </fieldset>
      ))}

      <button
        className="inline-block rounded-lg bg-blue-soft px-8 py-3 text-sm font-medium text-white transition hover:scale-105 hover:shadow-xl focus:outline-none focus:ring active:bg-blue-600"
        type="button"
        onClick={onClose}
      >
        Guardar cambios!
      </button>
    </div>
  )
}

export default Prices
