import PropTypes from 'prop-types'
import { FaHouse } from 'react-icons/fa6'
import { ABOUT_ME } from '@assets/constants'
import { useMemo } from 'react'

function AboutSitterHouse({ data, sitterName }) {
  const aboutMe = useMemo(
    () =>
      ABOUT_ME.filter((item) =>
        data?.some((aboutItem) => aboutItem === item.value),
      ),
    [data],
  )
  if (!data || data?.length === 0) {
    return (
      <div className="flex flex-col items-start justify-center gap-2">
        <h2 className="font-mont-semi text-slate-900 sm:text-lg">
          Acerca de {sitterName}
        </h2>
        <div className="mt-4 flex w-full flex-col items-center justify-center gap-2 text-sm text-grey-soft">
          <FaHouse className="h-12 w-12" />
          <p className="w-64 text-center">
            No hay información disponible sobre {sitterName}
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col items-start justify-center gap-2">
      <div className="flex flex-col gap-1">
        <h2 className="font-mont-semi text-slate-900 sm:text-lg">
          Acerca del hogar de {sitterName}
        </h2>
        <p className="text-sm text-grey-soft">Servicio de Alojamiento</p>
      </div>
      <div className="flex flex-wrap gap-2">
        {aboutMe.map((item, index) => (
          <div
            key={index}
            className="cursor-pointer rounded-full bg-blue-ter px-2 py-1.5 text-xs text-white transition-all duration-200 hover:scale-95"
          >
            <p>{item.label}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

AboutSitterHouse.propTypes = {
  data: PropTypes.object.isRequired,
  sitterName: PropTypes.string.isRequired,
}

export default AboutSitterHouse
