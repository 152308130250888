import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useGetProfile } from '@services/user'
import { FaExclamationTriangle } from 'react-icons/fa'
import { putData } from '@services/requests'
import Button from '@common/buttons/Button'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import ErrorNoCard from '@common/error/ErrorNoCard'

function DeleteSitterModal({ isOpen, onClose, sitter }) {
  const modalClass = isOpen
    ? 'fixed inset-0 flex items-center justify-center'
    : 'hidden'
  const { data: user } = useGetProfile()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const {
    isPending: updateIsPending,
    isError: updateIsError,
    error: updateError,
    mutate: updateSitter,
  } = useMutation({
    mutationFn: async () => {
      const data = await putData(
        `sitter-request/delete-sitter/${sitter.id}/${user.id}`,
      )
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['adminSitters'] })
      onClose()
      navigate('/admin')
    },
  })

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-black/80 ${modalClass} z-50`}
    >
      <div className="relative z-10 max-w-md overflow-y-auto rounded-xl bg-white p-4 shadow-lg">
        <button
          onClick={onClose}
          className="absolute right-0 top-0 p-4 text-3xl text-grey-base focus:outline-none"
        >
          &times;
        </button>
        <div className="mt-4 flex flex-col gap-8">
          <h2 className="text-center text-xl font-bold">
            ¿Estas seguro que quieres eliminar a {sitter.name} como sitter?
          </h2>
          <div className="flex justify-center text-red-base">
            <FaExclamationTriangle size={52} />
          </div>
          <p className="text-center text-sm leading-loose text-grey-sec">
            Eliminar el sitter implica eliminar su disponibilidad, ademas
            aquellos servicios solicitados o aceptados por {sitter.name}{' '}
            quedaron <strong>cancelados</strong>. Eliminar al sitter no implica
            eliminar su cuenta.
          </p>
          <Button
            onClick={() => updateSitter()}
            bgColor="red-base"
            textColor="white-base"
            hoverColor="opacity-80"
            text="Eliminar Sitter"
            circleColor="white-base"
            fillColor="red-soft"
            loading={updateIsPending}
          />
        </div>
        {updateIsError ? <ErrorNoCard message={updateError.message} /> : null}
      </div>
    </div>
  )
}

DeleteSitterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sitter: PropTypes.object.isRequired,
}

export default DeleteSitterModal
