import { useState, useEffect } from 'react'
import Loading from '@common/utils/Loading'
import ErrorNoCard from '@common/error/ErrorNoCard'
import { useGetAddresses, useUpdateAddress } from '@services/address'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import AddressForm from '@common/forms/AddressForm'
import { putData } from '@services/requests'
import { Switch } from '@headlessui/react'

function Addresses() {
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [activeAddresses, setActiveAddresses] = useState({})
  const [counters, setCounters] = useState({})
  const { mutate: putAddress } = useUpdateAddress()
  const {
    data: addresses,
    isSuccess,
    isPending,
    isError,
    error,
  } = useGetAddresses()

  useEffect(() => {
    if (isSuccess) {
      const initialActiveStates = {}
      const initialCounters = {}

      addresses.forEach((address) => {
        initialActiveStates[address.id] = address.isSitter // Inicializa según el atributo isSitter
        initialCounters[address.id] = address.radius || ' ' // Inicializa los contadores en 10
      })

      setActiveAddresses(initialActiveStates)
      setCounters(initialCounters)
    }
  }, [addresses, isSuccess])

  const toggleActive = async (addressId) => {
    const isActive = activeAddresses[addressId]
    const body = {
      addressId: addressId,
    }

    try {
      // Espera a que putData se complete
      await putData(`address/sitter-address/${addressId}`, body)

      // Actualiza el estado: activa la nueva dirección y desactiva las otras
      setActiveAddresses((prevState) => {
        const newState = {}

        // Establece todas las direcciones como inactivas excepto la seleccionada
        Object.keys(prevState).forEach((id) => {
          newState[id] = id === addressId ? !isActive : false
        })

        return newState
      })
    } catch (error) {
      console.error('Error al actualizar la dirección:', error)
      // Aquí podrías manejar el error, como mostrar un mensaje al usuario
    }
  }

  const handleCounterChange = (addressId, value) => {
    if (value >= 0) {
      // Asegúrate de que no sea negativo
      setCounters((prevState) => ({
        ...prevState,
        [addressId]: value,
      }))
      const body = {
        radius: value,
      }
      putAddress({ id: addressId, body: body })
    }
  }

  return (
    <>
      <div>
        <h2 className="font-tex-bold text-base font-semibold leading-7 text-gray-900">
          Direcciones
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          📍 Selecciona tu dirección como paseador y define el radio en
          kilómetros en el que deseas ofrecer tus servicios.
        </p>

        {isPending && <Loading />}

        <ul
          role="list"
          className="mt-6 divide-y divide-gray-200 border-t border-gray-300 text-sm leading-6"
        >
          {isSuccess &&
            addresses.map((address) => (
              <li
                key={address.id}
                className="flex justify-between gap-x-6 py-6"
              >
                <div className="line-clamp-2 w-1/2 font-medium text-gray-900 lg:max-w-md">
                  {address.formattedAddress}, {address.department}
                </div>
                <div className="flex items-center space-x-4">
                  {/* Input con contador y km */}
                  <div className="flrx-row flex items-end">
                    <input
                      type="number"
                      value={
                        counters[address.id] !== undefined
                          ? counters[address.id]
                          : 0
                      }
                      min="0"
                      onChange={(e) =>
                        handleCounterChange(address.id, Number(e.target.value))
                      }
                      className="w-16 rounded border border-gray-300 px-2 py-1 text-center"
                    />
                    <span className="ml-1 text-xs text-gray-500">Km</span>
                  </div>

                  {/* Switch */}
                  <Switch
                    checked={!!activeAddresses[address.id]}
                    onChange={() => toggleActive(address.id)}
                    className={`${
                      activeAddresses[address.id]
                        ? 'bg-blue-500'
                        : 'bg-gray-300'
                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                  >
                    <span
                      className={`${
                        activeAddresses[address.id]
                          ? 'translate-x-6'
                          : 'translate-x-1'
                      } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                  </Switch>
                </div>
              </li>
            ))}
        </ul>

        <div className="flex border-t border-gray-100 pt-6">
          <button
            type="button"
            onClick={() => setOpenCreateModal(true)}
            className="text-sm font-semibold leading-6 text-blue-soft hover:text-blue-600"
          >
            <span aria-hidden="true">+</span> Agrega una dirección
          </button>
        </div>
        {isError && <ErrorNoCard error={error} />}
      </div>
      <CustomModal
        header={<ModalHeader title="Agrega una dirección" />}
        body={<AddressForm onSubmit={() => setOpenCreateModal(false)} />}
        isOpen={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
      />
    </>
  )
}

export default Addresses
