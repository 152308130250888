/* eslint-disable react/prop-types */
import * as Yup from 'yup'
import { useState } from 'react'
import { yupValidator } from '@tanstack/yup-form-adapter'
import { useForm } from '@tanstack/react-form'
import { GoogleLogin } from '@react-oauth/google'
import { Link } from 'react-router-dom'
import { useSignIn, useGoogleAuth, useCompleteRegistration} from '@services/auth'
import CustomModal, { ModalHeader } from '@common/layouts/CustomModal'
import FormInput from '@common/inputs/FormInput'
import FormPassword from '../inputs/FormPassword'
import FormButton from '@common/buttons/FormButton'

const validations = {
  email: Yup.string()
    .email('Correo electrónico inválido')
    .required('Debes ingresar un correo electrónico'),
  password: Yup.string().required('Debes ingresar una contraseña'),
  rut: Yup.string()
    .matches(/^\d{1,2}\.\d{3}\.\d{3}-[\dkK]$/, 'El RUT debe ser válido')
    .required('El RUT es obligatorio'),
  phone: Yup.string()
    .matches(
      /^9(\s*\d{4}\s*\d{4}|\d{4}\s*\d{4}|\s*\d{6})$/,
      'El teléfono debe contener solo números',
    )
    .required('El teléfono es obligatorio'),
}

function SignInForm() {
  const [isOpen, setIsOpen] = useState(false)
  const [googleToken, setGoogleToken] = useState(null)
  const { mutate: signIn, isPending } = useSignIn()
  const { mutate: googleAuth } = useGoogleAuth(setIsOpen, setGoogleToken)

  const form = useForm({
    onSubmit: ({ value }) => {
      signIn(value)
    },
    validatorAdapter: yupValidator(),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  return (
    <div className="space-y-6">
      <form
        onSubmit={(e) => {
          e.preventDefault()
          e.stopPropagation()
          form.handleSubmit()
        }}
        className="space-y-4"
      >
        <form.Field name="email" validators={{ onChange: validations.email }}>
          {(field) => (
            <FormInput
              field={field}
              name="email"
              label="Correo Electrónico"
              placeholder="Escribe tu correo electrónico"
            />
          )}
        </form.Field>
        <form.Field
          name="password"
          validators={{ onChange: validations.password }}
        >
          {(field) => (
            <FormPassword
              field={field}
              name="password"
              label="Contraseña"
              placeholder="Escribe tu contraseña"
              forgotPassword={true}
            />
          )}
        </form.Field>

        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
        >
          {([canSubmit, isSubmitting]) => (
            <FormButton
              theme="blue"
              isPending={isSubmitting || isPending}
              isDisabled={!canSubmit}
              text={'Iniciar Sesión'}
            />
          )}
        </form.Subscribe>
      </form>
      <hr className="border-grey-soft" />
      <GoogleLogin
        clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}
        onSuccess={(credentialResponse) => googleAuth(credentialResponse)}
        onError={(error) => console.log('Login Failed:', error)}
        width={350}
        useOneTap
      />
      <p className="mt-8 text-center text-xs text-gray-500">
        ¿No tienes cuenta?{' '}
        <Link
          to="/signup"
          className="leading-6 text-blue-600 hover:text-blue-500"
        >
          Regístrate
        </Link>
      </p>
      <CustomModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        header={
          <ModalHeader
            title="Completa tu registro!"
            subtitle="Para mejorar tu experiencia con Pets-Okey, necesitamos un
                    par de detalles más sobre ti. 🐶"
          />
        }
        body={<CompleteRegistration googleToken={googleToken} />}
      />
    </div>
  )
}

const CompleteRegistration = ({ googleToken }) => {
  const { mutate: completeRegistration, isPending } = useCompleteRegistration()
  const form = useForm({
    onSubmit: ({ value }) => {
      completeRegistration({ ...value, ...googleToken })
    },
    validatorAdapter: yupValidator(),
    defaultValues: {
      rut: '',
      phone: '',
    },
  })
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        form.handleSubmit()
      }}
      className="space-y-4"
    >
      <form.Field name="rut" validators={{ onChange: validations.rut }}>
        {(field) => (
          <FormInput
            field={field}
            name="rut"
            label="RUT"
            placeholder="Escribe tu RUT"
            isRut
          />
        )}
      </form.Field>
      <form.Field name="phone" validators={{ onChange: validations.phone }}>
        {(field) => (
          <FormInput
            field={field}
            name="phone"
            label="Teléfono"
            placeholder="Escribe tu teléfono"
            isPhone
          />
        )}
      </form.Field>
      <form.Subscribe
        selector={(state) => [state.canSubmit, state.isSubmitting]}
      >
        {([canSubmit, isSubmitting]) => (
          <FormButton
            theme="blue"
            isPending={isSubmitting || isPending}
            isDisabled={!canSubmit}
            text={'Completar Registro'}
          />
        )}
      </form.Subscribe>
    </form>
  )
}

export default SignInForm
