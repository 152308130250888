import PropTypes from 'prop-types'
import { useGetProfile } from '@services/user'
import { Link } from 'react-router-dom'
import { formatDateReview } from '@utils/formatFunctions'
import { useEffect, useState } from 'react'

const NotificationCard = ({ notification, handleDeleteClick }) => {
  const { data: user } = useGetProfile()
  const [linkRedirect, setLinkRedirect] = useState('')

  useEffect(() => {
    const newLinkRedirect =
      notification?.userId === user?.id
        ? `user/service-records?serviceRecordId=${notification?.servicerecordId}`
        : `sitter/service-records?serviceRecordId=${notification?.servicerecordId}`
    setLinkRedirect(newLinkRedirect)
  }, [notification, user])

  const formattedDate = formatDateReview(notification?.createdAt)?.toLowerCase()

  if (notification?.type === 'chat') {
    return (
      <Link to={linkRedirect}>
        <div
          key={notification?.id}
          href="#"
          className="relative -mx-2 flex items-center border-b hover:bg-gray-100"
        >
          <button
            type="button"
            className="ghost absolute right-4 top-0 text-xl hover:text-red-500 focus:outline-none"
            onClick={() => handleDeleteClick(notification?.id)}
          >
            &times;
          </button>

          <p className="mx-2 px-4 py-3 text-sm text-gray-600">
            <span className="font-bold">
              Tienes un nuevo mensaje. Revisa la{' '}
            </span>
            <span className="font-bold text-blue-600">
              Solicitud de servicio
            </span>
            <span className="absolute bottom-2 right-5">
              {' ' + formattedDate}
            </span>
          </p>
        </div>
      </Link>
    )
  }

  if (notification?.type === 'sitter') {
    return (
      <Link to="/sitter-request">
        <div
          key={notification?.id}
          href="#"
          className="relative -mx-2 flex items-center border-b hover:bg-gray-100"
        >
          <button
            type="button"
            className="ghost absolute right-4 top-0 text-xl hover:text-red-500 focus:outline-none"
            onClick={() => handleDeleteClick(notification?.id)}
          >
            &times;
          </button>

          <p className="mx-2 px-4 py-3 text-sm text-gray-600">
            <span className="font-bold">
              Tienes una actualizacion. Revisa el estado de tu{' '}
            </span>
            <span className="font-bold text-blue-600">Solicitud</span>
            <span className="absolute bottom-2 right-5">
              {'. ' + formattedDate}
            </span>
          </p>
        </div>
      </Link>
    )
  }

  return (
    <Link to={linkRedirect}>
      <div
        key={notification?.id}
        href="#"
        className="relative -mx-2 flex items-center border-b hover:bg-gray-100"
      >
        <button
          type="button"
          className="ghost absolute right-4 top-0 text-xl hover:text-red-500 focus:outline-none"
          onClick={() => handleDeleteClick(notification?.id)}
        >
          &times;
        </button>

        <p className="mx-2 px-4 py-3 text-sm text-gray-600">
          <span className="font-bold">
            Tienes una actualizacion. Revisa la{' '}
          </span>
          <span className="font-bold text-blue-600">Solicitud de servicio</span>
          <span className="absolute bottom-2 right-5">
            {' ' + formattedDate}
          </span>
        </p>
      </div>
    </Link>
  )
}

NotificationCard.propTypes = {
  notification: PropTypes.object.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
}

export default NotificationCard
