import PropTypes from 'prop-types'
import { useState, useRef, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { FaPaw, FaStar, FaStarHalf, FaHeart } from 'react-icons/fa'
import ReactStars from 'react-rating-stars-component'
import { useGetProfile } from '@services/user'
import profilePhoto from '@assets/images/others/profile.png'
import SuperSitterBadge from '@common/tooltip/SupperSitterBadge'
import InfoToolTip from '@common/tooltip/InfoToolTip'
import ExpandedInfo from './ExpandedInfo'
import SitterLoadingCard from './SitterLoadingCard'
import Error from '@common/error/Error'
import useFilter from '@zustand/filter'
import { useUpdateFavoriteSitter, useGetSitterInfo } from '@services/user'

function SitterCard({ sitterId }) {
  const { data: user } = useGetProfile()
  const cardRef = useRef(null)
  const [isExpanded, setIsExpanded] = useState(false)
  const [isFavorite, setIsFavorite] = useState(
    user?.id ? user?.favoriteSitters?.includes(sitterId) : false,
  )

  const { selectedType } = useFilter()
  const { isPending, isError, error, data } = useGetSitterInfo(sitterId)

  const { mutate } = useUpdateFavoriteSitter()

  const handleUpdateFavorite = (data) => {
    mutate(data, {
      onSuccess: () => {
        setIsFavorite(!isFavorite)
      },
    })
  }

  const handleClickOutside = (event) => {
    if (cardRef.current && !cardRef.current.contains(event.target)) {
      setIsExpanded(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  if (isPending && !data) return <SitterLoadingCard cardRef={cardRef} />

  if (isError) return <Error error={error.message} />

  return (
    <div
      ref={cardRef}
      onClick={() => setIsExpanded(!isExpanded)}
      className={`rounded-xl ${data?.isSuperSitter && 'border-4 border-yellow-base'} bg-white p-3 shadow-xl`}
    >
      {data?.isSuperSitter && (
        <div className="rounded-md bg-yellow-base px-4 py-2 sm:absolute sm:left-1/2 sm:-translate-x-1/2 sm:transform">
          <InfoToolTip
            InfoToShow={<SuperSitterBadge />}
            text="Super Sitter"
            icon={<FaPaw />}
            style="flex flex-row items-center justify-center gap-2 text-yellow-700 text-sm mx-auto"
          />
        </div>
      )}

      <div
        className={`flex w-full flex-row justify-between ${
          data?.isSuperSitter && 'mt-4'
        }`}
      >
        <div className="flex w-[50%] flex-col gap-4 sm:w-[80%] sm:flex-row">
          <img
            className="object-fit h-24 w-24 rounded-full sm:h-32 sm:w-32"
            src={data?.photoUrl ? data.photoUrl : profilePhoto}
            alt={data?.name}
          />
          <div className="flex flex-col gap-2">
            <p className="text-lg">
              {data?.name} {data?.lastName}
            </p>
            <div className="flex flex-col gap-2">
              <ReactStars
                count={5}
                value={Math.round(data?.stars * 2) / 2}
                size={24}
                isHalf={true}
                edit={false}
                filledIcon={<FaStar />}
                halfIcon={<FaStarHalf />}
                emptyIcon={<FaStar />}
                color={'#D9D9D9'}
                classNames="pl-1"
              />
              <p className="pl-1 font-mont-semi text-xs text-slate-700">
                {data?.stats?.finished ?? 0} Servicios Realizados
              </p>
              <p className="pl-1 text-xs text-grey-base">
                {data?.distance
                  ? `A ${data.distance} km de tu ubicación`
                  : 'Distancia no disponible'}
              </p>
            </div>
          </div>
        </div>
        <div className="flex h-[50%] items-center gap-4">
          {user?.id && (
            <div
              onClick={() => handleUpdateFavorite(data)}
              className="flex h-12 w-12 cursor-pointer items-center justify-center rounded-full border border-grey-soft p-2 hover:bg-grey-soft"
            >
              <FaHeart color={isFavorite ? 'red' : 'gray'} size={24} />
            </div>
          )}
          <div className="flex-1 text-end">
            <p className="text-lg text-grey-base">Desde</p>
            <p className="text-3xl font-bold text-blue-base">
              $
              {data?.prices[selectedType || 'dogWalking']
                ? data?.prices[selectedType || 'dogWalking']
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                : data?.prices['dogWalking']
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.') || 'N/A'}
            </p>

            <p className="text-lg text-grey-base">
              {selectedType == 'dogWalking'
                ? 'el paseo'
                : selectedType == 'visit'
                  ? 'la visita'
                  : 'el dia'}
            </p>
          </div>
        </div>
      </div>
      <p
        className={`hidden w-full break-words text-left text-sm text-grey-base sm:pl-4 ${
          isExpanded ? 'sm:line-clamp-none' : 'sm:line-clamp-2'
        }`}
      >
        {data?.description}
      </p>
      <Transition
        className="w-full"
        show={isExpanded}
        enter="transition-all duration-300 ease-in-out"
        enterFrom="opacity-0 transform -translate-y-8 h-0"
        enterTo="opacity-100 transform translate-y-0 h-40 sm:h-16"
        leave="transition-all duration-300 ease-in-out"
        leaveFrom="opacity-100 transform translate-y-0 h-40 sm:h-16"
        leaveTo="opacity-0 transform -translate-y-8 h-0"
      >
        <div className="mt-4 flex w-full flex-wrap justify-center gap-6 sm:justify-between sm:gap-8">
          <ExpandedInfo sitter={data} />
        </div>
      </Transition>
    </div>
  )
}

SitterCard.propTypes = {
  sitterId: PropTypes.string.isRequired,
}

export default SitterCard
