/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useGetProfile } from '@services/user'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FaFileInvoiceDollar, FaRegCopy } from 'react-icons/fa'
import CountUp from 'react-countup'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { putData } from '@services/requests'
import { classNames } from '@utils/formatFunctions'
import Button from '@common/buttons/Button'
import ErrorNoCard from '@common/error/ErrorNoCard'

function Debt({ sitter, debtData }) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => {
    setIsModalOpen(true)
    document.body.classList.add('overflow-hidden')
  }

  const closeModal = () => {
    setIsModalOpen(false)
    document.body.classList.remove('overflow-hidden')
  }

  return (
    <div className="w-full space-y-5 rounded-xl bg-white p-4 shadow-xl">
      <div className="relative space-y-1">
        <Menu as="div" className="absolute right-0">
          <div>
            <Menu.Button className="flex items-center rounded-lg bg-white text-slate-400 hover:bg-slate-100 hover:text-slate-600">
              <span className="sr-only"></span>
              <EllipsisHorizontalIcon className="h-7 w-7" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/sitter/finished-records"
                    state={{ records: debtData.records }}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    )}
                  >
                    Detalles
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={openModal}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-4 py-2 text-left text-sm',
                    )}
                  >
                    Pagar
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
        <div className="flex gap-2">
          <p className="font-tex-bold text-xl text-slate-900">Resumen deuda</p>
          <div className="flex items-center justify-center text-xs">
            <p
              className={`flex flex-row items-center justify-center gap-2 rounded-full px-2 py-1 ${
                debtData?.totalDebt > 0
                  ? 'bg-red-100 text-red-base'
                  : 'bg-green-100 text-green-base'
              }`}
            >
              <FaFileInvoiceDollar size={12} />
              {debtData?.totalDebt > 0
                ? `${debtData.records.filter((record) => record.paid == false).length} servicio(s) pendientes`
                : 'Pagado'}
            </p>
          </div>
        </div>

        <p className="text-sm text-grey-sec">
          Detalles de lo que le debemos a {sitter.name}
        </p>
      </div>
      <div className="flex items-center justify-between">
        <span className="text-grey-sec">Monto total</span>
        <CountUp
          end={debtData?.totalAmount ? debtData.totalAmount : 0}
          duration={1.5}
          prefix="$"
          className="text-xl font-bold text-grey-base"
        />
      </div>
      <div className="flex items-center justify-between">
        <span className="text-grey-sec">Nuestro Fee</span>
        <CountUp
          end={debtData?.totalFee ? debtData.totalFee : 0}
          duration={1.5}
          prefix="$"
          className="text-xl font-bold text-green-base"
        />
      </div>
      <hr />
      <div className="flex items-center justify-between">
        <span className="text-grey-sec">Deuda total</span>
        <CountUp
          end={debtData?.totalDebt ? debtData.totalDebt : 0}
          duration={1.5}
          prefix="$"
          className="text-xl font-bold text-red-base"
        />
      </div>
      <PayDebtModal
        isOpen={isModalOpen}
        onClose={closeModal}
        sitter={sitter}
        debtData={debtData}
      />
    </div>
  )
}

function PayDebtModal({ onClose, isOpen, sitter, debtData }) {
  const modalClass = isOpen ? 'flex' : 'hidden'
  const { data: user } = useGetProfile()
  const queryClient = useQueryClient()

  const {
    isPending: updateIsPending,
    isError: updateIsError,
    error: updateError,
    mutate: updateDebt,
  } = useMutation({
    mutationFn: async () => {
      const data = await putData(`user/pay-sitter/${sitter.id}/${user.id}`)
      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries('adminSitterDebt')
      onClose()
    },
  })
  const handleCopy = () => {
    const textToCopy = `Nombre: ${sitter.name} \nRut: ${sitter.rut} \nMail: ${sitter.email}  \nBanco: ${sitter.bankAccount.bank} \nTipo de cuenta: ${sitter.bankAccount.type} \nN° de cuenta: ${sitter.bankAccount.accountNumber} \n`
    try {
      navigator.clipboard.writeText(textToCopy)
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-black/80 ${modalClass} z-50`}
    >
      <div className="relative z-10 overflow-y-auto rounded-xl bg-white p-4 shadow-lg">
        <button
          onClick={onClose}
          className="absolute right-0 top-0 p-4 text-3xl text-grey-base focus:outline-none"
        >
          &times;
        </button>
        <div className="mr-20 flex flex-row gap-4">
          <div>
            <p className="font-tex-bold text-xl text-slate-900">
              Resumen deuda
            </p>
            <p className="text-sm text-grey-sec">
              Detalles de lo que le debemos a {sitter.name}
            </p>
          </div>
          <button
            onClick={handleCopy}
            className="text-grey-soft hover:text-grey-sec"
          >
            <FaRegCopy size={24} />
          </button>
        </div>

        <div className="my-4 space-y-4">
          <div className="flex items-center justify-between">
            <span className="text-grey-sec">Monto total</span>
            <CountUp
              end={debtData.totalAmount ? debtData.totalAmount : 0}
              duration={1.5}
              prefix="$"
              className="text-xl font-bold text-grey-base"
            />
          </div>
          <div className="flex items-center justify-between">
            <span className="text-grey-sec">Nuestro Fee</span>
            <CountUp
              end={debtData.totalFee ? debtData.totalFee : 0}
              duration={1.5}
              prefix="$"
              className="text-xl font-bold text-green-base"
            />
          </div>
          <hr />
          <div className="flex items-center justify-between">
            <span className="text-grey-sec">Deuda total</span>
            <CountUp
              end={debtData.totalDebt ? debtData.totalDebt : 0}
              duration={1.5}
              prefix="$"
              className="text-xl font-bold text-red-base"
            />
          </div>
        </div>
        <Button
          onClick={() => updateDebt()}
          bgColor="green-base"
          textColor="white-base"
          hoverColor="opacity-80"
          text="Pagar"
          circleColor="green-base"
          fillColor="green-soft"
          loading={updateIsPending}
        />
        {updateIsError && <ErrorNoCard message={updateError.message} />}
      </div>
    </div>
  )
}

Debt.propTypes = {
  sitter: PropTypes.object.isRequired,
  debtData: PropTypes.object.isRequired,
}

export default Debt
