import { useSelector } from 'react-redux'
import SitterCard from './SitterCard'
import sitterNotFound from '@assets/images/home/sitterNotFound.png'
import LazyLoad from 'react-lazyload'

function Sitters() {
  const sittersIds = useSelector((state) => state.sitter)

  if (!sittersIds?.length) {
    return (
      <div className="mx-auto my-32 flex max-w-2xl flex-col items-center justify-center gap-4">
        <h1 className="text-md text-center text-grey-base md:text-2xl">
          No encontramos ningún sitter que cumpla con tus criterios de búsqueda
          😔
        </h1>
        <p className="text-center text-xs text-grey-sec md:text-base">
          Por favor, intenta con otros filtros o comunícate con nosotros para
          que te ayudemos a encontrar un sitter.
        </p>
        <img
          src={sitterNotFound}
          alt="No hay sitters disponibles"
          className="h-20 md:h-32"
        />
      </div>
    )
  }

  return (
    <div className={'my-4 flex flex-col gap-4 transition-all duration-700'}>
      {sittersIds.map((id) => (
        <LazyLoad key={id} height={200} offset={100}>
          <SitterCard key={id} sitterId={id} />
        </LazyLoad>
      ))}
    </div>
  )
}

export default Sitters
