import { useQuery } from '@tanstack/react-query'
import { useGetProfile } from '@services/user'
import { useState } from 'react'
import { getData } from '@services/requests'
import Loading from '@common/utils/Loading'
import Error from '@common/error/Error'
import SearchInput from '@common/inputs/SearchInput'
import CustomTable from '@common/layouts/CustomTable'
import { formatDate } from '@utils/formatFunctions'
import {
  StarsRenderer,
  descriptionRenderer,
} from '@common/helpers/tableRenderers'

function ReviewList() {
  const { data: user } = useGetProfile()
  const [searchTerm, setSearchTerm] = useState('')

  const { isPending, isError, error, data } = useQuery({
    queryKey: ['adminReviews'],
    queryFn: () => getData(`review/admin/${user.id}`),
  })

  const filteredData = data
    ? data.filter(
        (review) =>
          (review.user.name + ' ' + review.user.lastName)
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          (review.sitter.name + ' ' + review.sitter.lastName)
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          review.id.toString().includes(searchTerm) ||
          review.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
          review.createdAt
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()),
      )
    : null

  if (isPending) {
    return <Loading />
  }

  const tableProps = [
    {
      key: 'userFullName',
      header: { name: 'Cliente' },
    },
    {
      key: 'sitterFullName',
      header: { name: 'Sitter' },
    },
    {
      key: 'stars',
      header: { name: 'Estrellas', style: { textAlign: 'center' } },
      renderer: StarsRenderer,
    },
    {
      key: 'description',
      header: {
        name: 'Descripción',
        style: { textAlign: 'center' },
      },
      renderer: descriptionRenderer,
    },
    {
      key: 'createdAt',
      header: {
        name: 'Fecha',
      },
      formatter: formatDate,
    },
  ]

  let tableData = []
  if (filteredData) {
    tableData = filteredData.map((review) => {
      return {
        id: review.id,
        userFullName: review.user.name + ' ' + review.user.lastName,
        sitterFullName: review.sitter.name + ' ' + review.sitter.lastName,
        stars: review.stars,
        description: review.description,
        createdAt: review.createdAt,
      }
    })
  }

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <SearchInput
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder={'Buscar por ID, cliente, sitter, etc.'}
      />
      <CustomTable data={tableData} tableProps={tableProps} />
      {isError ? <Error message={error.message} /> : null}
    </div>
  )
}

export default ReviewList
