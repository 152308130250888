import { useState } from 'react'
import { useGetProfile } from '@services/user'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { getData } from '@services/requests'
import Loading from '@common/utils/Loading'
import Error from '@common/error/Error'
import { formatDate } from '@utils/formatFunctions'
import { statusRenderer } from '@common/helpers/tableRenderers'
import SearchInput from '@common/inputs/SearchInput'
import CustomTable from '@common/layouts/CustomTable'

function SittersRequestsList() {
  const { data: user } = useGetProfile()
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState('')

  const { isPending, isError, error, data } = useQuery({
    queryKey: ['sitterRequests'],
    queryFn: () => getData(`sitter-request/admin/${user.id}`),
  })

  const filteredData = data
    ? data.filter(
        (request) =>
          (request.user.name + ' ' + request.user.lastName)
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          request.user.rut.toLowerCase().includes(searchTerm.toLowerCase()) ||
          request.id.toString().includes(searchTerm) ||
          ('solicitado'.includes(searchTerm.toLowerCase()) &&
            request.status === 'pending') ||
          ('aceptado'.includes(searchTerm.toLowerCase()) &&
            request.status === 'accepted') ||
          ('rechazado'.includes(searchTerm.toLowerCase()) &&
            request.status === 'denied') ||
          request.createdAt
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()),
      )
    : null

  const tableProps = [
    {
      key: 'date',
      header: {
        name: 'Fecha',
      },
      formatter: formatDate,
    },
    {
      key: 'userFullName',
      header: { name: 'Nombre' },
    },
    {
      key: 'rut',
      header: { name: 'RUT' },
    },
    {
      key: 'status',
      header: { name: 'Estado', style: { textAlign: 'center' } },
      renderer: statusRenderer,
    },
  ]

  let tableData = []
  if (filteredData) {
    tableData = filteredData.map((request) => {
      return {
        id: request.id,
        date: request.createdAt,
        userFullName: `${request.user.name} ${request.user.lastName}`,
        rut: request.user.rut,
        status: request.status,
      }
    })
  }

  if (isPending) return <Loading />

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <SearchInput
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        placeholder={'Buscar por ID, fecha, nombre, etc.'}
      />
      <CustomTable
        data={tableData}
        tableProps={tableProps}
        rowClickHandler={(request) =>
          navigate(`/admin/sitter-request/${request.id}`)
        }
      />
      {isError ? <Error message={error.message} /> : null}
    </div>
  )
}

export default SittersRequestsList
